
import { useStore } from "vuex";
import { defineComponent, onMounted, onUnmounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { translate } from "@/hooks/TranslateService";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "auth-layout",
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();

    const pageName = computed(() => {
      return route.name;
    });

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "it";

    const countries = {
      en: {
        flag: "/media/flags/united-kingdom.svg",
        name: "English",
      },
      it: {
        flag: "/media/flags/italy.svg",
        name: "Italiano",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });
    watch(i18n.locale, (val) => {
      location.reload();
    });

    onMounted(() => {
      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      MenuComponent.reinitialization();
    });

    onUnmounted(() => {
      // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      setLang,
      currentLanguage,
      translate,
      currentLangugeLocale,
      countries,
      pageName,
    };
  },
});
