import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "name"]
const _hoisted_2 = { class: "d-md-block" }
const _hoisted_3 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded fw-bold fs-7 w-200px py-4",
  "data-kt-menu": "true"
}
const _hoisted_4 = { class: "menu-item px-3" }
const _hoisted_5 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "button",
      class: "btn-language-switcher btn btn-flex flex-center btn-active-color-primary h-40px px-6 px-md-6 bg-medium-gray",
      alt: _ctx.translate('LANGUAGE_SELECTED'),
      role: "button",
      "data-kt-menu-trigger": "hover",
      "data-kt-menu-placement": "bottom-start",
      id: "language-button",
      "aria-expanded": "false",
      "aria-controls": "language-button",
      "aria-haspopup": "listbox",
      name: _ctx.translate('languages')
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate("languages")), 1)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLang('en'))),
          href: "javascript:void(0)",
          class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('en') }]),
          alt: "Select English Language"
        }, " English ", 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLang('it'))),
          href: "javascript:void(0)",
          class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('it') }]),
          alt: "Seleziona Lingua Italiana"
        }, " Italiano ", 2)
      ])
    ])
  ], 64))
}