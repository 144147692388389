
import { defineComponent, ref } from "vue";
import { translate } from "@/hooks/TranslateService";
import KTFooter from "@/layout/footer/Footer.vue";
import MobileFooter from "@/layout/footer/MobileFooter.vue";
import LanguageSwitcher from "@/components/features/LanguageSwitcher.vue";

export default defineComponent({
  name: "home",
  components: { KTFooter, MobileFooter, LanguageSwitcher },
  setup() {

    document.title = translate("HOMEPAGE_PAGE_TITLE")
    
    const opportunities = ref([
      {
        image: "/media/vectorial/opportunities/icon_1.svg",
        title: "LANDING_EASY_AND_SIMPLE_TO_USE",
        content: "LANDING_EASY_AND_SIMPLE_TO_USE_DETAIL",
      },
      {
        image: "/media/vectorial/opportunities/icon_2.svg",
        title: "LANDING_COMPLETE_OVERVIEW",
        content: "LANDING_COMPLETE_OVERVIEW_DETAIL",
      },
      {
        image: "/media/vectorial/opportunities/icon_3.svg",
        title: "LANDING_COMPARE_WITH_OTHER_COMPANY",
        content: "LANDING_COMPARE_WITH_OTHER_COMPANY_DETAIL",
      },
      {
        image: "/media/vectorial/opportunities/icon_4.svg",
        title: "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION",
        content: "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION_DETAIL",
      },
    ]);
    return {
      translate,
      opportunities,
    };
  },
});
